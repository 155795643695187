import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {
  Breadcrumbs,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import Airtable from 'airtable';
import { SelectChangeEvent } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const Form: FC = () => {
  const { id, user } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [title, setTitle] = useState('');
  const [recommendation, setRecommendation] = useState('');
  const [avis, setAvis] = useState('');
  const [support, setSupport] = useState('');
  const [updateReview, setUdpateReview] = useState<boolean>(false);
  const [idReview, setIdReview] = useState('');

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setRecommendation(event.target.value as string);
  };

  useEffect(() => {
    const success = searchParams.get('update');
    if (success && !updateReview) setUdpateReview(true);
  }, [searchParams]);

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setLoadingSubmit(true);
    const base = Airtable.base('app9ef8TIGgFh5rVn');

    if (updateReview && idReview) {
      base('Retour impatience').update(
        [
          {
            id: idReview,
            fields: {
              Notes: avis,
              Status: recommendation,
              ['Bug support']: support,
            },
          },
        ],
        (err) => {
          setLoadingSubmit(false);
          if (err) {
            console.error(err);
            return;
          }
          navigate(`/liste-projets/${user}?success=true`);
        },
      );
    } else {
      base('Retour impatience').create(
        [
          {
            fields: {
              Jury: [`${user}`],
              Notes: avis,
              Status: recommendation,
              ['Bug support']: support,
              Projet: [`${id}`],
            },
          },
        ],
        (err) => {
          setLoadingSubmit(false);
          if (err) {
            console.error(err);
            return;
          }
          navigate(`/liste-projets/${user}?success=true`);
        },
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const base = Airtable.base('app9ef8TIGgFh5rVn');
      const tableProjects = base('tblv1psCsmRGbTkbY');
      const tableReview = base('tblaTUyiMYGZbE29F');
      const filterByFormula = `IF(AND("${id}" = {projet id}, "${user}" = {jury id}), TRUE(), FALSE())`;

      if (id && user) {
        const record = await tableProjects.find(id);
        const review = await tableReview
          .select({ filterByFormula })
          .firstPage();

        if (review.length > 0) {
          setAvis(review[0].fields['Notes'] as string);
          setRecommendation(review[0].fields['Status'] as string);
          setSupport(review[0].fields['Bug support'] as string);
          setIdReview(review[0].id);
        }
        setTitle(record.fields['Titre du projet proposé'] as string);
        setLoading(false);
      }
    };

    setLoading(true);

    fetchData().catch(console.error);
  }, [id, user]);

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      {loading ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={500}
          sx={{ borderRadius: 3, mt: 4 }}
        />
      ) : (
        <>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4 }}>
            <Link
              underline="hover"
              color="inherit"
              href={`/liste-projets/${user}`}
            >
              Liste projets
            </Link>
            <Typography color="blue">{`Avis spectacle : "${title}"`}</Typography>
          </Breadcrumbs>
          <Paper variant="outlined" sx={{ mt: 3, p: { xs: 2, md: 3 } }}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              marginBottom={3}
            >
              Votre avis
            </Typography>

            <Typography variant="h6" marginBottom={3}>
              Spectacle : {title}
            </Typography>

            <form onSubmit={onSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-recommendation">
                      Recommandation
                    </InputLabel>
                    <Select
                      labelId="select-recommendation"
                      value={recommendation}
                      label="Recommendation"
                      onChange={handleChangeSelect}
                    >
                      <MenuItem value="Oui">Oui</MenuItem>
                      <MenuItem value="Peut-être">Peut-être</MenuItem>
                      <MenuItem value="Non">Non</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Avis"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    value={avis}
                    minRows={7}
                    placeholder="Exemple : D'un point de vue formel, c'est une pièce très aboutie, pro, tout est maîtrisé du jeu des comédiens à la musique... Mais pour moi ça manque de charme et d'innovation. Visuellement il n'y a rien à dire, c'est pro et efficace, mais je ne vois rien qui me donne l'impression de voir le renouveau ou le futur du théâtre. Peut-être que c'est le texte qui ne me touche pas beaucoup non plus."
                    onChange={({ target }) => setAvis(target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Problème de support"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    value={support}
                    minRows={2}
                    placeholder="Exemple : le lien de fonctionne pas"
                    onChange={({ target }) => setSupport(target.value)}
                  />
                </Grid>
              </Grid>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  sx={{ mt: 3, ml: 1 }}
                  loading={loadingSubmit}
                >
                  {updateReview ? 'Modifier' : 'Valider'}
                </LoadingButton>
              </Box>
            </form>
          </Paper>
        </>
      )}
    </Container>
  );
};

export default Form;
