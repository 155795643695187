import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Airtable from 'airtable';

Airtable.configure({
  apiKey:
    'pateMfrPFDe4YQlWt.4bce880744932413ad037341947e19f2a298410aa3ca6ff0038df28814e24159',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
