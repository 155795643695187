import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import Form from './Form';
import List from './List';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AppBar, Toolbar, Typography } from '@mui/material';
import SignIn from './SignIn';

const theme = createTheme();

const App: FC = () => (
  <div className="App">
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6" color="inherit" noWrap>
            Sélection festival Impatience
          </Typography>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/avis-projet/:user/:id" element={<Form />} />
        <Route path="/liste-projets/:user" element={<List />} />
      </Routes>
    </ThemeProvider>
  </div>
);

export default App;
