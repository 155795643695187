import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Airtable from 'airtable';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';

export default function SignIn() {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const base = Airtable.base('app9ef8TIGgFh5rVn');
    const table = base('tblhDYHkuy20GLixL');
    const filterByFormula = `IF("${data.get(
      'email',
    )}" = {email}, TRUE(), FALSE())`;

    const user = await table.select({ filterByFormula }).firstPage();

    if (user.length === 0) {
      setError('Adresse email incorrecte');
      setLoading(false);
    } else {
      navigate(`/liste-projets/${user[0].id}`);
    }
    console.log(user);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography component="h1" variant="h5">
          Retrouver les captations à visionner
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            InputLabelProps={{ shrink: true }}
            label="Votre email 104"
            name="email"
            autoComplete="email"
            placeholder="prenom.nom@104.fr"
            autoFocus
            error={error !== ''}
            helperText={error}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            Accéder
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
}
