import { FC, forwardRef, SyntheticEvent, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  Button,
  Skeleton,
  Link,
  IconButton,
  Box,
  Snackbar,
  TableContainer,
} from '@mui/material';
import Airtable from 'airtable';
import {
  Link as LinkRouter,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { Container } from '@mui/system';
import DoneIcon from '@mui/icons-material/Done';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
interface Rows {
  id: string;
  artist: string;
  name: string;
  duration: string;
  captation: string;
  password: string;
  done: boolean;
  first: boolean;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const List: FC = () => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<Rows[]>([]);
  const { user } = useParams();
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
    setOpen(false);
  };

  useEffect(() => {
    const success = searchParams.get('success');
    if (success && !openSuccess) setOpenSuccess(true);
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      const base = Airtable.base('app9ef8TIGgFh5rVn');
      const tableProjects = base('tblv1psCsmRGbTkbY');
      const filterByFormula = `IF(SEARCH("${user}", ARRAYJOIN({ids jury}, ";")), TRUE(), FALSE())`;

      const records = await tableProjects
        .select({
          filterByFormula,
        })
        .all();

      if (records.length > 0) {
        const rows: Rows[] = records?.map((record) => ({
          id: record.id,
          name: record.fields['Titre du projet proposé'] as string,
          artist: record.fields[
            'Nom du metteur ou de la metteuse en scène'
          ] as string,
          duration: record.fields['Durée totale projet proposé'] as string,
          captation: record.fields['Captation'] as string,
          password: record.fields['Mot de passe'] as string,
          first: record.fields['Premier visionnage'] as boolean,
          done:
            user &&
            record.fields['ids jury retour'] &&
            (record.fields['ids jury retour'] as [string]).includes(user)
              ? true
              : false,
        }));

        setRows(rows);
      }

      setLoading(false);
    };

    setLoading(true);

    fetchData().catch(console.error);
  }, []);

  return (
    <Container component="main" sx={{ pb: 5 }}>
      <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Copié !
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Avis envoyé avec succés !
        </Alert>
      </Snackbar>
      <Paper sx={{ marginTop: 5 }} elevation={4}>
        <TableContainer>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Titre du projet</TableCell>
                <TableCell>Mise en scène</TableCell>
                <TableCell>Durée</TableCell>
                <TableCell>Premier visionnage</TableCell>
                <TableCell>Lien captation</TableCell>
                <TableCell>Mot de passe</TableCell>
                <TableCell width={40} />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? [...Array(5)].map((_, index) => (
                    <TableRow key={index}>
                      <TableCell colSpan={5}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          height={20}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                : rows.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.artist}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.duration}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.first ? (
                          <CheckBoxIcon color="success" />
                        ) : (
                          <CheckBoxOutlineBlankIcon color="success" />
                        )}
                      </TableCell>
                      <TableCell>
                        <Link href={row.captation} target="_blank">
                          {row.captation}
                        </Link>
                      </TableCell>
                      <TableCell width={150}>
                        {row.password && (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {row.password}
                            <IconButton
                              sx={{ marginLeft: 1 }}
                              size="small"
                              onClick={() => {
                                navigator.clipboard.writeText(row.password);
                                setOpen(true);
                              }}
                            >
                              <ContentCopyRoundedIcon />
                            </IconButton>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.done ? (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'end',
                            }}
                          >
                            <DoneIcon color="success" />
                            <LinkRouter
                              to={`/avis-projet/${user}/${row.id}?update=true`}
                              style={{ textDecoration: 'none' }}
                            >
                              <IconButton sx={{ marginLeft: 1 }} size="small">
                                <EditRoundedIcon fontSize="small" />
                              </IconButton>
                            </LinkRouter>
                          </Box>
                        ) : (
                          <LinkRouter
                            to={`/avis-projet/${user}/${row.id}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <Button>Soumettre</Button>
                          </LinkRouter>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default List;
